exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-templates-archive-post-list-query-tsx": () => import("./../../../src/templates/archive/post-list-query.tsx" /* webpackChunkName: "component---src-templates-archive-post-list-query-tsx" */),
  "component---src-templates-archive-post-list-tsx": () => import("./../../../src/templates/archive/post-list.tsx" /* webpackChunkName: "component---src-templates-archive-post-list-tsx" */),
  "component---src-templates-page-about-us-tsx": () => import("./../../../src/templates/page/about-us.tsx" /* webpackChunkName: "component---src-templates-page-about-us-tsx" */),
  "component---src-templates-page-contact-tsx": () => import("./../../../src/templates/page/contact.tsx" /* webpackChunkName: "component---src-templates-page-contact-tsx" */),
  "component---src-templates-page-explore-tsx": () => import("./../../../src/templates/page/explore.tsx" /* webpackChunkName: "component---src-templates-page-explore-tsx" */),
  "component---src-templates-page-glossaries-tsx": () => import("./../../../src/templates/page/glossaries.tsx" /* webpackChunkName: "component---src-templates-page-glossaries-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/page/home.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-page-topics-tsx": () => import("./../../../src/templates/page/topics.tsx" /* webpackChunkName: "component---src-templates-page-topics-tsx" */),
  "component---src-templates-recommend-format-recommend-tsx": () => import("./../../../src/templates/recommend/format-recommend.tsx" /* webpackChunkName: "component---src-templates-recommend-format-recommend-tsx" */),
  "component---src-templates-recommend-read-recommend-tsx": () => import("./../../../src/templates/recommend/read-recommend.tsx" /* webpackChunkName: "component---src-templates-recommend-read-recommend-tsx" */),
  "component---src-templates-recommend-topic-recommend-tsx": () => import("./../../../src/templates/recommend/topic-recommend.tsx" /* webpackChunkName: "component---src-templates-recommend-topic-recommend-tsx" */),
  "component---src-templates-recommend-watch-recommend-tsx": () => import("./../../../src/templates/recommend/watch-recommend.tsx" /* webpackChunkName: "component---src-templates-recommend-watch-recommend-tsx" */),
  "component---src-templates-single-resource-post-tsx": () => import("./../../../src/templates/single-resource/post.tsx" /* webpackChunkName: "component---src-templates-single-resource-post-tsx" */)
}

